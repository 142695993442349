let contactRouteDetails = [
  {
    id: 0,
    route: "/raunak",
    name: "raunak",
    profileName: "Raunak",
  },
  {
    id: 1,
    route: "/rohit",
    name: "rohit",
    profileName: "Rohit",
  },
  {
    id: 2,
    route: "/pratik",
    name: "pratik",
    profileName: "Pratik",
  },
  {
    id: 3,
    route: "/gautam",
    name: "gautam",
    profileName: "Gautam",
  },
  {
    id: 4,
    route: "/drishti",
    name: "drishti",
    profileName: "Drishti",
  },
  {
    id: 5,
    route: "/eeshan",
    name: "eeshan",
    profileName: "Eeshan",
  },
  {
    id: 6,
    route: "/maulik",
    name: "maulik",
    profileName: "Maulik",
  },
  {
    id: 7,
    route: "/vaibhav",
    name: "vaibhav",
    profileName: "Vaibhav",
  },
  {
    id: 8,
    route: "/avi",
    name: "avanindra",
    profileName: "Avanindra",
  },
  {
    id: 9,
    route: "/saksham",
    name: "saksham",
    profileName: "Saksham",
  },
  {
    id: 10,
    route: "/varun",
    name: "varun",
    profileName: "Varun",
  },
  {
    id: 11,
    route: "/keertana",
    name: "keertana",
    profileName: "Keertana",
  },
  {
    id: 12,
    route: "/shrivardha",
    name: "shrivardha",
    profileName: "Shrivardha",
  },
  {
    id: 13,
    route: "/nitish",
    name: "nitish",
    profileName: "Nitish",
  },
  {
    id: 14,
    route: "/vishakha",
    name: "vishakha",
    profileName: "Vishakha",
  },
  {
    id: 15,
    route: "/gorang",
    name: "gorang",
    profileName: "gorang",
  },
];

export { contactRouteDetails };
